<template>
    <div class="basic_data_edit sn_table_group">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">景观风貌监测数据</span>
            </div>
            <sn-search
                ref="snSearch"
                class="sn-search clearfix"
                :snSearch.sync="snSearch"
                @handleChange="handleChange"
            ></sn-search>
            <div class="carouselPart carouselArrowShow">
                <el-carousel
                    v-if="carouselList.length"
                    indicator-position="none"
                    height="500px"
                    :autoplay="false"
                    direction="horizontal"
                    :arrow="carouselList.length > 1 ? 'hover' : 'never'"
                    v-viewer
                >
                    <el-carousel-item
                        v-for="(item, index) in carouselList"
                        :key="index"
                        class="pointer carouselItem flex-between"
                    >
                        <div
                            v-for="(item1, index1) in item"
                            :key="index1"
                            class="imgItem"
                        >
                            <el-image
                                :src="item1.JGZP"
                                fit="cover"
                                class="pointer"
                                style="width: 100%; height: 100%"
                            >
                                <div
                                    slot="error"
                                    style="width: 100%; height: 100%"
                                >
                                    <SnNoData
                                        width="150px"
                                        typeNum="1"
                                    ></SnNoData>
                                </div>
                            </el-image>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <SnNoData width="200px" v-else typeNum="1"></SnNoData>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    components: {},
    name: "jghjmonitor",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            carouselList: [],
            snSearch: {
                selectData: [
                    {
                        label: "",
                        clearable: false,
                        placeholder: "请选择景观风貌基础数据",
                        list: [],
                        default: "",
                        optionLabel: "MC",
                        optionValue: "ID",
                        value: "baseDataId",
                        operateType: "search",
                        isShow: true,
                        width: "200px",
                    },
                    {
                        label: "",
                        clearable: true,
                        placeholder: "请选择时段",
                        list: [
                            {
                                name: "早",
                                value: "6,7,8,9,10",
                            },
                            {
                                name: "中",
                                value: "11,12,13,14",
                            },
                            {
                                name: "晚",
                                value: "15,16,17,18,19",
                            },
                        ],
                        value: "timeSlot",
                        optionLabel: "name",
                        optionValue: "value",
                        operateType: "search",
                        isShow: true,
                    },
                ],
                dateData: [
                    {
                        id: "cjsj",
                        isShow: true,
                        type: "daterange",
                        rangeSeparator: "至",
                        startPlaceholder: "开始日期",
                        endPlaceholder: "结束日期",
                        operateType: "search",
                        value: "time",
                        width: "240px",
                    },
                ],
            },
        };
    },
    created() {},
    mounted() {
        this.jgfmBaseData();
    },
    methods: {
        ...mapActions(["getMonitorDataList"]),
        // 调用监测数据
        async jgfmMonitorData() {
            let startTime = "";
            let endTime = "";
            if (this.time && this.time.length) {
                startTime = this.time[0];
                endTime = this.time[1];
            }
            let strwhere = "";
            if (this.timeSlot) {
                strwhere = `[
                    "date_format(CJSJ,'%H'),${this.timeSlot}",
                    "JGSLID,${this.baseDataId}",
                ]`;
            } else {
                strwhere = `["JGSLID,${this.baseDataId}"]`;
            }
            let result = await this.getMonitorDataList({
                userid: localStorage.userId,
                itemId: 90304,
                authority: false,
                keyword: "",
                strwhere: strwhere,
                start: startTime,
                end: endTime,
                pageIndex: 1,
                pageSize: 1000,
            });
            let list = [];
            let length = result.ResultValue.gridData.length;
            if (length) {
                if (length == 1) {
                    result.ResultValue.gridData.map((item, index) => {
                        list.push([item]);
                    });
                } else {
                    result.ResultValue.gridData.map((item, index) => {
                        if (index < length - 1) {
                            list.push([
                                item,
                                result.ResultValue.gridData[index + 1],
                            ]);
                        }
                    });
                }
            }

            this.carouselList = list;
            console.log(
                "%c 🍹 this.carouselList: ",
                "font-size:20px;background-color: #2EAFB0;color:#fff;",
                this.carouselList
            );
        },
        // 景观风貌基础数据
        async jgfmBaseData() {
            let result = await this.getMonitorDataList({
                userid: localStorage.userId,
                itemId: 90303,
                authority: false,
                // keyword,
                // strwhere: strwhere,
                // sort: this.sort,
                // sortorder: this.order,
                // start: this.start,
                // end: this.end,
                pageIndex: 1,
                pageSize: 1000,
            });
            this.snSearch.selectData[0].list = result.ResultValue.gridData;
            if (result.ResultValue.gridData.length) {
                let id = result.ResultValue.gridData[0].ID;
                this.snSearch.selectData[0].default = id;
                this.baseDataId = id;
                this.jgfmMonitorData();
            }
        },
        handleChange(type, value) {
            console.log("type", type, "value", value);
            this[value[1]] = value[0];
            this.jgfmMonitorData();
            // this.searchData.forEach((item) => {
            //     if (item.type === value[1]) {
            //         item.value = value[0];
            //     }
            // });
        },
    },
};
</script>

<style scoped lang="scss">
.imgList {
    padding: 10px;
    .img {
        width: 50px;
        height: 50px;
        border-radius: 4px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
    }
}
.carouselPart {
    width: 100%;
    height: 500px;
    position: relative;
    .carouselItem {
        width: 100%;
        .imgItem {
            position: relative;
            width: calc(50% - 10px);
            height: 100%;
        }
    }
}
</style>
